import React, { useEffect } from 'react'
import { Radar } from '@antv/g2plot'

const data = [
    {
        "item": "设计",
        "user": "a",
        "score": 70
    },
    {
        "item": "设计",
        "user": "b",
        "score": 30
    },
    {
        "item": "发展",
        "user": "a",
        "score": 60
    },
    {
        "item": "发展",
        "user": "b",
        "score": 70
    },
    {
        "item": "营销",
        "user": "a",
        "score": 50
    },
    {
        "item": "营销",
        "user": "b",
        "score": 60
    },
    {
        "item": "用户",
        "user": "a",
        "score": 40
    },
    {
        "item": "用户",
        "user": "b",
        "score": 50
    },
    {
        "item": "测试",
        "user": "a",
        "score": 60
    },
    {
        "item": "测试",
        "user": "b",
        "score": 70
    },
    {
        "item": "语言",
        "user": "a",
        "score": 70
    },
    {
        "item": "语言",
        "user": "b",
        "score": 50
    },
    {
        "item": "科技",
        "user": "a",
        "score": 50
    },
    {
        "item": "科技",
        "user": "b",
        "score": 40
    },
    {
        "item": "支持",
        "user": "a",
        "score": 30
    },
    {
        "item": "支持",
        "user": "b",
        "score": 40
    },
    {
        "item": "销售",
        "user": "a",
        "score": 60
    },
    {
        "item": "销售",
        "user": "b",
        "score": 40
    },
    {
        "item": "用户体验",
        "user": "a",
        "score": 50
    },
    {
        "item": "用户体验",
        "user": "b",
        "score": 60
    }
]

export default function RadarPlot() {
    useEffect(() => {
        const radar = new Radar('radar', {
            data,
            xField: 'item',
            yField: 'score',
            seriesField: 'user',
            meta: {
                score: {
                    alias: '分数',
                    min: 0,
                    max: 80,
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: null,
                        },
                    },
                },
            },
            yAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        type: 'line',
                        style: {
                            lineDash: null,
                        },
                    },
                },
            },
            // 开启面积
            area: {},
            // 开启辅助点
            point: {
                size: 2,
            },
        });
        radar.render();
    }, [])
    return (
        <div id='radar' />
    )
}
