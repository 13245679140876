import { Card } from 'antd';
import Empt from '../../components/side/Empt';

const About = () => (
    <Card
        title="关于作者"
        bordered={false}
        style={{
            height: "100%",
            textAlign: "left"
        }}
    >
        <Empt />
    </Card>
);
export default About;