import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import {
    UserOutlined,
    LockOutlined,
} from '@ant-design/icons';
import "./index.css";
import logo from "../../logo.svg";
import Drawe from "../../components/side/Drawe";
import FootLabel from "../../components/foot/FootLabel";
// import * as THREE from 'vanta/vendor/three.r134.min.js';
import CLOUD from 'vanta/dist/vanta.clouds.min';
import { reqLogin } from '../../api/login';
import toastUtil from '../../utils/toastutil';
/**
|--------------------------------------------------
| 登录页
|--------------------------------------------------
*/
export default function Login() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loginCount, setLoginCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [vantaEffect, setVantaEffect] = useState(0);
    const myRef = useRef(null);

    //动态背景
    useEffect(() => {
        // console.log(window.screen.availHeight - 81);
        //根据页面高动态设置背景的高
        document.getElementsByClassName("login")[0].style.height = window.innerHeight + "px";
        if (!vantaEffect) {
            setVantaEffect(CLOUD({
                el: myRef.current,
                // THREE,
                mouseControls: false,
                touchControls: false,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                skyColor: 0x6eb2e3,
                cloudColor: 0x4c5470,
                cloudShadowColor: 0x727287,
                sunColor: 0xff9100,
                sunGlareColor: 0xffa200,
                sunlightColor: 0xff7100,
                speed: 1.50
            }))
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect])

    //能正确登录时触发
    const onFinish = (values) => {
        //首先将loading值设置为true加载中
        setLoading(true);
        //判断登陆次数
        if (loginCount > 2) {
            toastUtil("登录失败次数已达三次,禁止登录!", '❌')
            return
        }
        //弹框设置加载中
        msg('loading', '登录中...', "login");
        //登录次数加1
        setLoginCount(loginCount + 1);

        // 发送请求
        reqLogin(values)
            .then((res) => {
                //判断返回status是否为0
                if (res.status === 0) {
                    //将 res.token 对象转化为JSON存入sessionStorage
                    sessionStorage.setItem("user", JSON.stringify(res.token))
                    //跳转到首页
                    navigate("/home")
                } else {
                    //登陆失败的弹窗
                    msg('error', res.message, "login")
                    if (loginCount === 0) {
                        toastUtil("看看右下角↘", '🤗')
                    }
                }
                //将loading值设置为flase 解锁登录按钮
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                msg('error', '请求出现问题！')
            })
    };
    //无法登录时触发
    const onFinishFailed = async () => {
        toastUtil("请输入用户名和密码!", '❌')
    };
    //当点击重置按钮触发
    const enterLoading = () => { }
    // 抽取通用弹窗的方法
    const msg = (type, content, key, duration) => {
        messageApi.open({
            key,
            type,
            content,
            duration,
        });
    }
    return (
        <div className='login' ref={myRef}>
            <div className='login-from'>
                {contextHolder}
                <img src={logo} className="login-logo" alt="logo" />
                <Form
                    name="basic"
                    wrapperCol={{
                        span: 18,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '用户名不能为空!',
                            },
                        ]}
                    >
                        <Input size="large" prefix={<UserOutlined />} placeholder=' Please enter username ...' />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '密码不能为空!',
                            },
                        ]}
                    >
                        <Input.Password size="large" prefix={<LockOutlined />} placeholder=' Please enter passwrod ...' />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" ghost htmlType="submit" loading={loading} style={{ marginRight: 45, marginLeft: 65 }}>
                            登录
                        </Button>
                        <Button type="primary" ghost danger htmlType="reset" onClick={enterLoading}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div >
            <Drawe />
            <div className="foot-label"><FootLabel /></div>
        </div>
    )
}
