import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Breadcrumb,
    Menu,
    Layout,
    Tag,
} from 'antd'
import {
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons'
import toastutil from '../../utils/toastutil'
import menuList from '../../config/menuList'
import logo from "../../logo.svg"
import { Outlet } from 'react-router-dom'
import './index.css'
import screenfull from 'screenfull'

const { Header, Sider, Content } = Layout;

export default function Home() {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    //当前页面的url
    const [current, setCurrent] = useState('dashboard');
    //当前页面的label
    const [currentName, setCurrentName] = useState(null);
    //当前子页面的label
    const [currentChild, setCurrentChild] = useState(null);
    //标签列表
    const [tagList, setTagList] = useState([]);
    //是否全屏
    const [isFullscreen, setIsFullscreen] = useState(false);

    //弹框
    useEffect(() => {
        // console.log(document.fullscreenElement);
        //动态设置Content的最大高
        document.getElementsByClassName("Content")[0].style.maxHeight = window.innerHeight - 110 + "px"
        const { username } = JSON.parse(sessionStorage.getItem("user"));
        toastutil(`Hello ${username}!`, '👏');
    }, [])

    //处理面包屑路由以及标签路由的方法
    useEffect(() => {
        navigate(current, { replace: true })
        if (current === "dashboard") {
            setCurrentName(null)
            setCurrentChild(null)
            return
        }
        let menu = menuList.filter((e) => e.key === current)
        let child = null
        if (!menu.length) {
            menu = menuList.filter((e) => e.key === current.substring(0, current.length - 2))
            child = menu[0].children.filter(e => e.key === current)[0].label

        }
        setCurrentName(menu[0].label)
        setCurrentChild(child)
        if (!tagList.filter(e => e.url === current).length) {
            setTagList([...tagList, { url: current, label: child ? child : menu[0].label }])
        }
    }, [current])

    //退出登录
    const loginout = (e) => {
        sessionStorage.clear();
        navigate("/login", { replace: true });
    }

    //切换折叠
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    //菜单Item跳转方法
    const toJump = (e) => {
        // const path = window.location.pathname;
        // if (path.substring(path.lastIndexOf("/") + 1) === e.key) {
        //     return
        // }
        setCurrent(e.key)
        // navigate(`/home/${e.key}`, { replace: true })
    }

    //删除Tag方法
    const closeTag = (event, url) => {
        event.preventDefault();
        const list = tagList.filter(e => e.url !== url);
        setTagList(list);
        if (url === current) {
            if (list.length) {
                setCurrent(list[list.length - 1].url);
            } else {
                setCurrent('dashboard')
            }
        }
    }

    //全屏
    const fullScreen = (e) => {
        if (!screenfull.isEnabled) {
            toastutil('浏览器不支持全屏!', '❌')
        }
        screenfull.toggle();
        setIsFullscreen(!isFullscreen)
    }

    return (
        <div className='Home'>
            {/* <Toaster /> */}
            <Layout>

                <Sider className='Sider'
                    trigger={null}
                    collapsed={collapsed}
                    collapsible
                >
                    <div className='Logo'>
                        {!collapsed ? <h2><i>{JSON.parse(sessionStorage.getItem("user")).username}</i></h2> : <></>}
                        <img src={logo} alt="logo" />
                    </div>
                    <Menu
                        defaultSelectedKeys={['1']}
                        mode="inline"
                        theme="dark"
                        items={menuList}
                        selectedKeys={[current]}
                        onClick={toJump}
                    />
                </Sider>

                <Layout>

                    <Header className='Header' style={{ backgroundColor: "white" }}>
                        <Button className='Collapsed' onClick={toggleCollapsed}>{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button>
                        <Breadcrumb className='Breadcrumb'>
                            <Breadcrumb.Item href='' onClick={(e) => {
                                e.preventDefault()
                                setCurrent('dashboard')
                            }}>
                                <HomeOutlined />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {currentName}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {currentChild}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Button className='Fullscreen' onClick={fullScreen}>{isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}</Button>
                        <Button className='Logout' onClick={loginout}></Button>
                    </Header>

                    <div className='TagList'>
                        <Tag color={"dashboard" === current ? "red" : "blue"} onClick={() => setCurrent("dashboard")}>首页</Tag>
                        {tagList.map(e => <Tag closable onClose={(event) => closeTag(event, e.url)} color={e.url === current ? "red" : "blue"} key={e.url} onClick={() => setCurrent(e.url)}>{e.label}</Tag>)}
                    </div>

                    <Content className='Content'>
                        <Outlet />
                    </Content>

                </Layout>
            </Layout>
        </div >
    )
}
