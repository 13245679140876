import Mock from 'mockjs';
import loginAPI from "./login";
import tableAPI from "./table";

Mock.setup({ timeout: '500-1000' });

// 登录与用户相关
Mock.mock(/\/login/, "post", loginAPI.login);

// 表格数据
Mock.mock(/\/table/, "get", tableAPI.getList);

export default Mock;