import React from 'react'
import { Col, Row } from 'antd'
import PiePlot from '../../components/Plot/PiePlot'
import RadarPlot from '../../components/Plot/RadarPlot'

const plotStyle = {
    backgroundColor: "white",
    borderRadius: 15,
    padding: "50px 0",
}

export default function keyboard() {
    return (
        <div style={{ height: "90%", margin: "20px 10px" }}>
            <Row style={{ margin: 10, }}>
                <Col span={11} style={plotStyle}><RadarPlot /></Col>
                <Col span={1}></Col>
                <Col span={12} style={plotStyle}><PiePlot /></Col>
            </Row>
        </div>
    )
}
