import { Card } from 'antd';

const GuestPage = () => (
    <Card
        title="guest页面"
        bordered={false}
        style={{
            textAlign: "left"
        }}
    >
        这个页面只有admin和guest角色才可以访问，editor角色看不到
    </Card>
);
export default GuestPage;