import React from 'react';
import Route from "./router/router";

function App() {
  return (
    <div>
      <Route />
    </div>
  );
}

// import React, { useState, useEffect, useRef } from 'react'
// import BIRDS from 'vanta/dist/vanta.birds.min'

// const App = (props) => {
//   const [vantaEffect, setVantaEffect] = useState(0)
//   const myRef = useRef(null)
//   useEffect(() => {
//     if (!vantaEffect) {
//       setVantaEffect(BIRDS({
//         el: myRef.current,
//         Three
//       }))
//     }
//     return () => {
//       if (vantaEffect) vantaEffect.destroy()
//     }
//   }, [vantaEffect])
//   return <div ref={myRef}>
//   </div>
// }

export default App;
