import React, { useEffect } from 'react'
import { Pie } from '@antv/g2plot';

const data = [
    { type: '类一', value: 27 },
    { type: '类二', value: 25 },
    { type: '类三', value: 18 },
    { type: '类四', value: 15 },
    { type: '类五', value: 10 },
    { type: '其他', value: 5 },
];

export default function PiePlot() {
    useEffect(() => {
        const pie = new Pie('pie', {
            appendPadding: 1,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 0.8,
            label: {
                type: 'outer',
                content: '{name} {percentage}',
            },
            interactions: [{ type: 'element-active' }, { type: 'element-highlight ' }],
        });
        pie.render();
    }, [])
    return (
        <div id='pie' />
    )
}
