import { useRoutes, Navigate } from "react-router-dom";
import Login from "../view/login"
import Home from "../view/home";

//首页

//项目简介

//引导页

//权限测试
import Permission from "../view/permission/";
import AdminPage from "../view/permission/adminPage";
import EditorPage from "../view/permission/editorPage";
import GuestPage from "../view/permission/guestPage";

//组件

//图表
import Keyboard from "../view/charts/keyboard";
import Line from "../view/charts/line";
import Mixchart from "../view/charts/mixchart";

//表格
import Table from "../view/table";

//Excel

//用户管理

//关于作者
import About from "../view/about/";

export default function Router() {
    return useRoutes([
        //登录页
        {
            path: "/login",
            element: <Login></Login>
        },
        //主页
        {
            path: "/home",
            element: <Home></Home>,
            children: [
                {
                    index: true,
                    element: <h1>首页</h1>
                },
                //首页
                {
                    path: "dashboard",
                    element: <h1>首页</h1>
                },
                //项目简介
                {
                    path: "doc",
                    element: <h1>项目简介</h1>
                },
                //引导页
                {
                    path: "guide",
                    element: <h1>引导页</h1>
                },
                //权限测试页
                {
                    path: "permission-1",
                    element: <Permission />
                },
                {
                    path: "permission-2",
                    element: <AdminPage />
                },
                {
                    path: "permission-3",
                    element: <GuestPage />
                },
                {
                    path: "permission-4",
                    element: <EditorPage />
                },
                //组件页
                {
                    path: "components-1",
                    element: <h1>components-2</h1>
                },
                {
                    path: "components-2",
                    element: <h1>components-2</h1>
                },
                //图标页
                {
                    path: "charts-1",
                    element: <Keyboard />
                },
                {
                    path: "charts-2",
                    element: <Line />
                },
                {
                    path: "charts-3",
                    element: <Mixchart />
                },
                //表格页
                {
                    path: "table",
                    element: <Table />
                },
                //excel页
                {
                    path: "excel-1",
                    element: <h1>excel-1</h1>,
                },
                {
                    path: "excel-2",
                    element: <h1>excel-2</h1>,
                },
                //用户管理页
                {
                    path: "user",
                    element: <h1>用户管理</h1>
                },
                //关于页
                {
                    path: "about",
                    element: <About />
                },
            ]
        },
        //重定向登录页
        {
            path: "/",
            element: <Navigate to={"/login"} />
        }
    ])
}