const tokens = [
    {
        username: "admin",
        password: "123456",
        role: "0"
    },
    {
        username: "guest",
        password: "123",
        role: "2"
    },
    {
        username: "editor",
        password: "123",
        role: "1"
    },
]

const login = {
    login: (config) => {
        const { username, password } = JSON.parse(config.body);
        const token = tokens.filter(e => e.username === username && e.password === password)
        if (!token.length) {
            return {
                status: 1,
                message: "用户名或密码错误",
            }
        }
        return {
            status: 0,
            token: token[0],
        }
    }
}

export default login;