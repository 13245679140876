import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd';
import {asyncGetList} from '../../redux/tableSlice'
import { useDispatch, useSelector } from 'react-redux';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: () => <Tag color="green">NICE</Tag>
    },
    {
        title: 'Action',
        key: 'action',
        render: () => <a>Delete</a>,
    },
]

export default function TablePage() {
    const data = useSelector( e => e.table.list)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(asyncGetList())
    }, [])
    return (
        <Table columns={columns} dataSource={data} />
    )
}
