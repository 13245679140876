import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { reqTable } from '../api/table';


export const asyncGetList = createAsyncThunk("getList", async () => {
    let { data } = await reqTable()
    return data;
})

export const tableSlice = createSlice({

    name: 'table',

    initialState: {
        list: []
    },

    reducers: {
        setList: (state, action) => {
            state.list = action.payload
        }
    },

    extraReducers: {
        [asyncGetList.fulfilled](state,action){
            state.list = action.payload;
        }
    }

})