import Mock from "mockjs";

const table = {
    getList: () => {
        return Mock.mock({
            status: 0,
            msg: "响应成功",
            "data|10": [
                {
                    id: "@id",
                    name: "@cname",
                    "age|1-100": 1,
                    address: "'@city(true)",
                }
            ]
        })
    }
}

export default table;