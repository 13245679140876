import { Card } from 'antd';

const EditorPage = () => (
    <Card
        title="editor页面"
        bordered={false}
        style={{
            textAlign: "left"
        }}
    >
        这个页面只有admin和editor角色才可以访问，guest角色看不到
    </Card>
);
export default EditorPage;