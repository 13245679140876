import toast from 'react-hot-toast'


const toastUtil = (info, icon) => {
    toast(info, {
        icon,
        style: {
            borderRadius: '15px',
            background: '#333',
            color: '#fff',
        },
    })
}

export default toastUtil;