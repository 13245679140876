import React, { useState } from 'react';
import { FloatButton, Drawer } from 'antd';
import { CodeTwoTone } from '@ant-design/icons';
import Empt from './Empt';

export default function Drawe() {
    const [open, setOpen] = useState(false);

    //抽屉弹出
    const showDrawer = () => {
        setOpen(true);
    };
    //抽屉关闭
    const onClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <FloatButton
                icon={<CodeTwoTone />}
                onClick={showDrawer}
                style={{
                    right: 24,
                }} />
            <Drawer title="您需要登录吗？" placement="right" onClose={onClose} open={open} width={300}>
                <span><b>账号: </b><i>admin</i><b> 密码: </b><i>123456</i></span>
                <hr style={{ margin: "20px 0" }} />
                <span><b>账号: </b><i>editor</i><b> 密码: </b><i>123</i></span>
                <hr style={{ margin: "20px 0" }} />
                <span><b>账号: </b><i>guest</i><b> 密码: </b><i>123</i></span>
                <div style={{ height: 80 }} />
                <Empt />
            </Drawer>
        </div >
    )
}
