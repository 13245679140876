import React from 'react';
import { Tag, Space } from 'antd';
import {
    FacebookOutlined,
    LinkedinOutlined,
    TwitterOutlined,
    YoutubeOutlined,
} from '@ant-design/icons';


export default function FootLabel() {
    return (
        <Space size={[20, 8]} wrap>
            <Tag icon={<TwitterOutlined />} color="#55acee">
                Twitter
            </Tag>
            <Tag icon={<YoutubeOutlined />} color="#cd201f">
                Youtube
            </Tag>
            <Tag icon={<FacebookOutlined />} color="#3b5999">
                Facebook
            </Tag>
            <Tag icon={<LinkedinOutlined />} color="#55acee">
                LinkedIn
            </Tag>
        </Space>
    )
}
