import { Card } from 'antd';

const AdminPage = () => (
    <Card
        title="admin页面"
        bordered={false}
        style={{
            textAlign: "left"
        }}
    >
        这个页面只有admin角色才可以访问，guest和editor角色看不到
    </Card>
);
export default AdminPage;