import {
    HomeOutlined,
    FileOutlined,
    KeyOutlined,
    AppstoreOutlined,
    AreaChartOutlined,
    TableOutlined,
    FileExcelOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    UnlockOutlined
} from '@ant-design/icons';

function getItem(label, key, icon, disabled, children) {
    return {
        key,
        icon,
        children,
        label,
        disabled,
    };
}

const menuList = [
    getItem('首页', 'dashboard', <HomeOutlined />),
    getItem('项目简介', 'doc', <FileOutlined />),
    getItem('引导页', 'guide', <KeyOutlined />),
    getItem('权限测试', 'permission', <UnlockOutlined />, false, [
        getItem('权限说明', 'permission-1'),
        getItem('admin页面', 'permission-2'),
        getItem('guest页面', 'permission-3'),
        getItem('editor页面', 'permission-4'),
    ]),
    getItem('组件', 'components', <AppstoreOutlined />, true, [
        getItem('富文本', 'components-1'),
        getItem('Markdown', 'components-2'),
    ]),
    getItem('图表', 'charts', <AreaChartOutlined />, false, [
        getItem('堆叠面积图', 'charts-1'),
        getItem('降水统计图', 'charts-2'),
        getItem('图标联动', 'charts-3'),
    ]),
    getItem('表格', 'table', <TableOutlined />),
    getItem('Excel', 'excel', <FileExcelOutlined />, false, [
        getItem('导出Excel', 'excel-1', null, true),
        getItem('上传Excel', 'excel-2', null, true),
    ]),
    getItem('用户管理', 'user', <UsergroupAddOutlined />),
    getItem('关于作者', 'about', <UserOutlined />),
];


export default menuList;