import React from 'react'
import MixPlot from '../../components/Plot/MixPlot'

export default function Line() {
    return (
        <div style={{ backgroundColor: "#344b58", borderRadius: 15,height: "90%",padding: "25px 25px",margin: "20px 10px" }}>
            <h1 style={{marginBottom: 80}}>中国各区年度信息</h1>
            <MixPlot />
        </div>
    )
}
