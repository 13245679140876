import axios from "axios";

//创建一个axios示例
const service = axios.create({
    baseURL: "/",
    timeout: 3000,
})

//请求拦截器
service.interceptors.request.use(
    config => config,
    err => Promise.reject(err)
)

// 响应拦截器
service.interceptors.response.use(
    res => res.data,
    err => Promise.reject(err)
)

export default service;