import React from 'react'
import LiquidPlot from '../../components/Plot/RainfallPlot'

export default function Line() {
    return (
        <div style={{ backgroundColor: "#dfe1e5", borderRadius: 15, height: "90%", padding: "20px 20px", margin: "20px 10px" }}>
            <h1 style={{ marginBottom: 80 }}>伦敦与柏林的降水量统计</h1>
            <LiquidPlot />
        </div>
    )
}
